
import {computed, defineComponent, ref, watch} from "vue";
import store from "@/store";
import {LoadEntity, LoadFilterObjects, LoadPanel} from "@/core/composite/composite";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import QuickAction from "@/components/base/action/QuickAction.vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import Panel from "@/components/base/panel/Panel.vue";
import ShortPageForm from "@/views/doc/ShortPageForm.vue";
import PageService from "@/core/services/DocService";
import DocService from "@/core/services/DocService";
import Swal from "sweetalert2";
import PageStatus from "@/views/doc/PageStatus.vue";
import Avatar from "@/components/base/common/Avatar.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import Folder from "@/views/doc/Folder.vue";
import FAQ from "@/views/doc/FAQ.vue";
import File from "@/views/doc/File.vue";
import DocUserService from "@/core/services/DocUserService";
import ProgressStatus from "@/views/training/ProgressStatus.vue";

export default defineComponent({
  name: "PageDetail",
  components: {
    ProgressStatus,
    File, FAQ, Folder, DateTimeFormat, Avatar, PageStatus, ShortPageForm, Panel, QuickAction
  },
  props: {
    pageId: {required: true, type: String}
  },
  setup(props) {
    const state = ref({title: '', show: false, id: null})
    const user = computed(() => store.getters.currentUser);
    const model = ref({
      name: '',
      type: '',
      body: '',
      parentId: '',
      description: '',
      status: '',
      config: {allowFile: true, allowFaq: true}
    })
    const refId = ref<any>(props.pageId);
    const filterObject = ref({parentId: refId.value, type: 'PAGE'});
    const pageEntity = computed(() => store.state.DocModule.pageEntity)
    const listPage = computed(() => store.state.DocModule.pages)
    watch(() => store.state.DocModule.pageEntity, (cb) => {
      let pageTitle = 'Library';
      if (cb.data.type === 'FOLDER') {
        pageTitle = 'Folder';
      }
      const data: any = []
      if (cb.data.type === 'TRAINING') {
        pageTitle = 'Training'
        data.push({
          link: true,
          router: `/` + user.value.currentWorkspaceId + '/training/all',
          text: 'Training'
        })
      } else {
        data.push({
          link: false,
          router: '',
          text: 'Libraries '
        })
      }
      if (cb.data.collection) {

        if (cb.data.collection.privacy !== 'HELP') {
          data.push({
            link: true,
            router: `/` + user.value.currentWorkspaceId + '/library/' + cb.data.collection.id,
            text: cb.data.collection.name
          })
        } else {
          data.push({
            link: true,
            router: '/help-center/' + cb.data.collection.id,
            text: cb.data.collection.name
          })
        }

      }
      if (cb.data.folder) {
        data.push({
          link: true,
          router: `/` + user.value.currentWorkspaceId + '/folder/' + cb.data.folder.id,
          text: cb.data.folder.name
        })
      }
      data.push({link: false, router: '', text: cb.data.name})
      setCurrentPageBreadcrumbsWithParams(pageTitle, data)
    })

    return {
      ...LoadEntity(Actions.LOAD_PAGE),
      ...LoadFilterObjects(Actions.LOAD_PAGES, filterObject.value, ['createdBy'], 'sort:order:asc'),
      pageEntity,
      user,
      listPage,
      filterObject,
      refId,
      model,
      state,
      ...LoadPanel(),
    }
  },
  watch: {
    pageId(objectId) {
      this.filterObject.parentId = objectId;
      this.updateFilterObject(this.filterObject)
    }
  },
  methods: {

    onStart(id) {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      DocUserService.start(id).then(res => {
        this.$router.push(`/` + this.user.currentWorkspaceId + "/training-lib/" + res.id);
      }).finally(() => {
        Swal.close();
      })
    },
    onEnableFAQ(id) {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      DocService.updateFaq(id, true).then(() => {
        this.reloadData(id)
      }).finally(() => {
        Swal.close();
      })
    },
    disableFAQ(id) {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      DocService.updateFaq(id, false).then(() => {
        this.reloadData(id)
      }).finally(() => {
        Swal.close();
      })
    },
    onClosePanel() {
      this.state.show = false;
    },
    onAdd(type) {
      this.model.name = '';
      this.model.type = type;
      this.model.parentId = this.pageId
      this.model.body = '';
      this.model.status = 'DRAFT';
      this.model.description = ''

      if (type === 'PAGE') {
        this.state.title = 'Add Document'
      } else {
        this.state.title = 'Add Folder'
      }
      this.state.show = true
      this.state.id = null
    },
    onEdit(page) {
      this.model.name = page.name;
      this.model.type = page.type;
      this.model.parentId = page.parentId
      this.model.body = page.body;
      this.model.status = page.staus;
      this.model.description = page.description
      this.model.config = page.config ? page.config : {allowFile: true, allowFaq: true}
      if (page.type === 'COLLECTION') {
        this.state.title = 'Edit Collection'
      } else if (page.type == 'FOLDER') {
        this.state.title = 'Edit Folder'
      } else {
        this.state.title = 'Edit Doc'
      }
      this.state.show = true
      this.state.id = page.id
    },
    onSavePage(data) {
      store.commit(Mutations.SET_PAGE, data);
      this.reloadData(this.pageId)
      this.paginationLoad();
      this.state.show = false;
    },

    updateStatus(id, status) {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      PageService.updateStatus(id, status).then(() => {
        this.reloadData(id);
      }).finally(() => {
        Swal.close();
      })
    },

    onDeleteEntity(page) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await PageService.delete(page.id).then(() => {
            store.commit(Mutations.DELETE_PAGE_ITEM, page)
            if (page.type === 'COLLECTION') {
              this.$router.push('/dashboard')
            } else {
              this.$router.push('/library/' + page.collection.id)
            }
          })
        }
      })
    },
    onDeleteItem(page) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await PageService.delete(page.id).then(() => {
            store.commit(Mutations.DELETE_PAGE_ITEM, page)
            this.paginationLoad()
          })
        }
      })
    }
  }
})
